<template>
  <b-col md="12" class="dropdown">
    <b-row>
      <b-col md="12" class="p-0">
        <!-- {{selected_object_array}} -->
        <!-- {{selected_object_id}} -->
        <b-input-group>
          <!-- {{ relationship_options[relation_alias] }} -->
          <template v-if="!show_editable_recursive_view">
            <b-form-select
              class="dropdown_select"
              v-if="relationship_options && relationship_options[relation_alias]"
              :options="relationship_options[relation_alias]"
              v-model="selected_option[relation_alias]"
              @input="onSelected"
              :disabled="disabled"
              :size="size"
            >
              <option slot="first" :value="null">-</option>
            </b-form-select>

            <b-form-select v-else class="dropdown_select" disabled :value="null" :size="size">
              <option :value="null">Loading...</option>
            </b-form-select>
          </template>
          <template v-else>
            <b-form-select class="dropdown_select" disabled :value="null" :size="size">
              <option :value="null">{{ $d.generateTitleFromTitlePropertyKeys(_.get(params, [relation_alias, 0]), title_property_keys) }}</option>
            </b-form-select>
          </template>

          <b-input-group-append>
            <b-button-group>
              <b-button v-if="show_details" :disabled="!selectedOptionData()" v-b-toggle="'details_' + relationship['relationship_key']" variant="secondary">
                <i class="fa fa-list"></i>
              </b-button>
              <b-button v-if="allow_force_refresh" @click="get()" variant="outline-secondary" v-b-tooltip.hover :title="'Reload'">
                <i class="fa fa-sync-alt"></i>
              </b-button>
              <b-button
                v-if="relationship['recursive']"
                @click="toggleEditableRecursiveView()"
                variant="outline-primary"
                :disabled="disabled || show_recursive_view"
                v-b-tooltip.hover
                :title="show_editable_recursive_view ? 'Cancel / Undo' : 'Add'"
              >
                <i v-if="!show_editable_recursive_view" class="fa fa-plus"></i>
                <i v-else class="fa fa-ban"></i>
              </b-button>
              <b-button
                v-if="relationship['recursive']"
                @click="toggleShowRecursiveView()"
                variant="outline-dark"
                :disabled="show_editable_recursive_view"
                v-b-tooltip.hover
                :title="show_recursive_view ? 'Hide' : 'Show'"
              >
                <i v-if="!show_recursive_view" class="fa fa-eye"></i>
                <i v-else class="fa fa-eye-slash"></i>
              </b-button>
            </b-button-group>
          </b-input-group-append>
        </b-input-group>
        <!-- {{ _.get(params, [relation_alias]) }} -->
        <template v-if="show_editable_recursive_view || show_recursive_view">
          <br />
          <RecursiveView
            ref="recursiveViewRef"
            :relationship="relationship"
            :params.sync="params"
            :disabled="disabled || $d.relIsViewOnly(relationship)"
            :mode="'flattened'"
            :title="'Add ' + _.startCase(relationship['to']['class_name'])"
            :find_button_visibility="{}"
            :show_action_bar="false"
            :force_init_item="true"
          />
          <hr />
        </template>
      </b-col>

      <b-col md="12" class="p-0" style="margin-top: 10px" v-if="show_details">
        <!-- <b-collapse v-if="selectedOptionData()" :id="'details_'+relationship['relationship_key']" class="mt-2" visible> -->
        <b-collapse v-if="selectedOptionData()" :id="'details_' + relationship['relationship_key']" class="mt-2">
          <b-table stacked bordered :items="[selectedOptionData()]" :fields="detail_table_fields">
            <template
              v-for="field in detail_table_fields"
              :slot="'cell(' + field.key + ')'"
              slot-scope="row"
            >
            <template v-if="field.property['property_key'] in row.item">
              {{ row.value }}
            </template>
            <template v-else>
              <span :key="field.key" class="text-black-50">not loaded</span>
            </template>
            </template>
          </b-table>
        </b-collapse>
      </b-col>
    </b-row>

    <!-- <b-modal
      v-if="relationship['recursive']"
      v-model="show_editable_recursive_view"
      :title="'Add ' + _.startCase(relationship['to']['class_name'])"
      title-tag="div"
      size="xl"
      centered
      hide-footer
      lazy
    >
      <div>
        <RecursiveView
          :relationship="relationship"
          :params.sync="params"
          :disabled="disabled || $d.relIsViewOnly(relationship)"
          :mode="'flattened'"
          :title="'Add ' + _.startCase(relationship['to']['class_name'])"
        />
      </div>
    </b-modal> -->
  </b-col>
</template>
<script>
import _ from 'lodash';

function doubleRaf(callback) {
  //ref: https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
  requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
}

export default {
  components: {
    RecursiveView: () => import('@/descriptor/coreui/components/modal/RecursiveView'),
  },
  props: {
    relationship: {
      type: Object,
    },
    dropdown_relationships: {
      //TODO: is dropdown_relationships really required in this component? meant for single relationship only
      type: Array,
    },
    params: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    _index_params: {
      type: Object,
    },
    _relationship_index_params: {
      type: Object,
    },
    size: {
      type: String,
      default: 'md',
    },
    allow_force_refresh: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      show_editable_recursive_view: false,
      show_recursive_view: false,
      selected_option: {}, //TODO: using this may break reactivity between dropdowns
      previous_selection: null,
    };
  },
  mounted() {
    console.log(this.relationship);
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
    class_() {
      return this.$d.getClass(this.relationship['to']['class_name']);
    },
    relationship_options() {
      return this.$store.state.descriptor['relationship_options'];
    },
    relation_alias() {
      return this.$d.getRelationAlias(this.relationship);
    },
    selected_object_array() {
      return this.params ? this.params[this.relation_alias] : null;
    },
    selected_object() {
      if(this.selected_object_array && this.selected_object_array.length > 0){
        return this.selected_object_array[0]
      }
      return null;
    },
    selected_object_id() {
      if (this.selected_object) {
        return this.selected_object['id'];
      }
      return null;
    },
    show_details() {
      return _.get(this.relationship, ['to', 'frontend', 'dropdown', 'details']) == true || _.get(this.relationship, ['flags', 'dropdown_details']) == true;
    },
    max_cache_age() {
      // return  12 * 3600 * 1000 //12 hours
      return 5 * 60 * 1000; //5 minutes
      // return 1 * 60 * 1000; //1 min
      // return 3 * 1000; //3 sec
    },
    title_property_keys() {
      return this.$d.getTitlePropertyKeys(this.class_)
    },
    detail_table_fields() {
      return this.$d.getClassBTableFields(this.relationship['to']['class_name']);
    }
  },
  watch: {
    selected_object_id(to, from) {
      if (!_.isEqual(to, from)) {
        console.log('params changed in dropdown.vue', this.relation_alias, this.selected_object_array, this.selected_object_id);
        let use_cache = true; //use cache if possible, because it's too heavy to reload every time
        this.init(use_cache); //TODO: to keep options up to date? too heavy
        this.$emit('update:params', this.params);
      }
    },
  },
  created() {
    this._ = _;
    // let use_cache = false; //don't use cache on create
    let use_cache = true; //use cache on create
    this.init(use_cache);
  },
  methods: {
    init(use_cache = true) {
      if (true) {
        //shouldn't matter if dropdown or not
        // if (this.relationship['flags'] && this.relationship['flags']['dropdown']) {
        // this.dropdown_relationships.push(this.relationship);

        console.log('init dropdown:', this.relation_alias);

        this.getFromCacheOrNew(this.relationship, this._index_params, use_cache);
        //initilise default select

        this.$set(this.selected_option, this.relation_alias, null);

        if (_.get(this.params, [this.relation_alias, 0])) {
          this.selected_option[this.relation_alias] = this.params[this.relation_alias][0]['id'];
        }
      }
    },
    toggleEditableRecursiveView() {
      this.show_editable_recursive_view = !this.show_editable_recursive_view;

      if (this.show_editable_recursive_view) {
        this.previous_selection = _.cloneDeep(this.params[this.relation_alias]); //backup
        this.params[this.relation_alias] = [];
        /* doubleRaf(() => {
          this.$refs['recursiveViewRef'].addItem(null, 'down', false);
        }); */
      } else {
        if (!_.isNil(this.previous_selection)) {
          this.params[this.relation_alias] = _.cloneDeep(this.previous_selection); //restore
        }
      }
    },
    toggleShowRecursiveView() {
      this.show_recursive_view = !this.show_recursive_view;
    },
    selectedOptionData() {
      if (this.relationship_options && this.relationship_options[this.relation_alias]) {
        var option = this.relationship_options[this.relation_alias].find(option => {
          return option.value === this.selected_option[this.relation_alias];
        });
        if (option) {
          if (option.real_data.polygon) option.real_data.polygon = null;
          if (option.real_data.gps) option.real_data.gps = null;
          if (option.real_data.center_point) option.real_data.center_point = null;
          return option.real_data;
        }
      }
    },
    onSelected(value) {

      let relationship = this.relationship;
      let relationship_option = this.relationship_options[this.relation_alias].find(option => {
        return option.value === value;
      });
      console.log('onSelected', this.relation_alias, value, relationship_option)

      // this.params[this.relation_alias] = [];
      // this.$set(this.params, this.relation_alias, []);

      if (!_.isNil(relationship_option)) {
          console.log('dropdown found relationship_option')
        //dropdown is always assuming only 1 item
        // this.params[this.relation_alias][0] = option.real_data;
        this.$set(this.params[this.relation_alias], 0, relationship_option.real_data);
      }else{
          console.log('dropdown cannot find relationship_option')
        if(value && this.selected_object && this.selected_object['id'] && this.relationship['recursive']){ 
          console.log('dropdown recursive preview')
          //if doesn't exist in list of options but has an id (likely from preview mode)
            /* let backup_selection = _.cloneDeep(this.params[this.relation_alias]);
            if(!this.show_editable_recursive_view){
              this.toggleEditableRecursiveView();
            }
            this.params[this.relation_alias] = _.map(backup_selection, (relation)=>{ */
            this.params[this.relation_alias] = _.map(this.params[this.relation_alias], (relation)=>{
              // return _.omit(relation, ['id', 'created_at', 'updated_at'])
              return _.omit(relation, ['id'])
            })
            // console.log(this.params[this.relation_alias])
            this.previous_selection = _.cloneDeep(this.params[this.relation_alias]); //backup
            this.show_editable_recursive_view = true; //force as new
          
        }else{
            console.log('dropdown.vue selected option does not exist in list', this.selected_object, this.relationship_options[this.relation_alias])
            this.params[this.relation_alias] = [];
            this.$set(this.params, this.relation_alias, []);
          }
      }

      //IMPORTANT TODO: move this logic into Dropdown View
      if (this.dropdown_relationships) {
        //Example: Once Specimen Type Category is changed, the Specimen Type's options will be reload.
        this.dropdown_relationships.forEach(other_relationship => {
          var rules = Object.values(_.omit(other_relationship['to']['rules']['default'], ['min', 'max']));
          rules.forEach(rule => {
            // console.log('checking other_relationship', other_relationship);
            if (rule['rule_name'] === 'same_relation_as') {
              console.log('other_relationship has same_relation_as:', other_relationship['relationship_name']);
              // if (rule['to_relation_relationship']['relationship_name'] === other_relationship['relationship_name']) { //TODO: this logic ceems to be totally off

              let direction = null;
              if (rule['to_relation_relationship']['relationship_name'] === relationship['relationship_name']) {
                direction = 'to';
              } else if (rule['from_relation_relationship']['relationship_name'] === relationship['relationship_name']) {
                direction = 'from';
              }

              if (direction) {
                //TODO: this logic ceems to be totally off
                console.log('either to_relation_relationship or to_relation_relationship matches relationship_name', direction);
                // this.params[this.$d.getRelationAlias(other_relationship)][0] = null;
                // this.params[this.$d.getRelationAlias(other_relationship)] = [];
                this.relationship_options[this.$d.getRelationAlias(other_relationship)] = null; // this does not follow direction, might be wrong in certain cases?

                let other_relationship_index_params = {};

                if (this._relationship_index_params && this._relationship_index_params[this.$d.getRelationAlias(other_relationship)]) {
                  other_relationship_index_params = this._relationship_index_params[this.$d.getRelationAlias(other_relationship)];
                }

                this.getFromCacheOrNew(other_relationship, other_relationship_index_params); //TODO: does not honor
                this.selected_option[this.$d.getRelationAlias(other_relationship)] = null; // this does not follow direction, might be wrong in certain cases?
              }
            }
          });
        });
      }
      //Example: Once Specimen Type is changed, the Specimen Type Categoty will be changed.
      var rules = Object.values(_.omit(relationship['to']['rules']['default'], ['min', 'max']));
      rules.forEach(rule => {
        if (rule['rule_name'] === 'same_relation_as') {
          console.log('dropdown has same_relation_as');
          // console.log('before_selected_option',this.selected_option);
          var param_name = this.getRelationAliasFromRule(rule, 'from_relation_relationship');
          var relationship_name = this.getRelationAliasFromRule(rule, 'to_relation_relationship');

          // if (
          //   this.params[this.relation_alias][0] &&
          //   this.params[this.relation_alias][0][relationship_name]
          // ) {

          console.log('dropdown ' + this.relation_alias, this.params[this.relation_alias]);
          console.log('dropdown ' + param_name, this.params[param_name]);

          if (this.params[this.relation_alias] && this.params[this.relation_alias][0] && this.params[this.relation_alias][0][relationship_name]) {
            // this.selected_option[param_name] = this.params[this.relation_alias][0][relationship_name][0];
            // this.params[param_name][0] = this.params[this.relation_alias][0][relationship_name][0];
          } else {
            // this.selected_option[param_name] = null;
            // this.params[param_name][0] = null;
          }
          // console.log('after_selected_option',this.selected_option);
        }
      });

      //TODO: this annyoingly causes search table to needlessly reload
      this.$emit('reload', this.params); // Note: This reload will affect other relationships for getting the data.
    },
    getRelationAliasFromRule(rule, relationship) {
      return _.snakeCase(rule[relationship]['from_class_name'] + rule[relationship]['relationship_name']);
    },
    getStartCase(text) {
      return _.startCase(text);
    },
    clearRetrievedData() {
      this.relationship_options[this.relation_alias] = null;
      this.$forceUpdate();
    },
    processRetrievedData(items, relationship) {
      let relation_alias = this.$d.getRelationAlias(relationship);
      this.relationship_options[relation_alias] = [];

      let class_ = this.$d.getClass(relationship['to']['class_name']);
      items.forEach(item => {
        this.relationship_options[relation_alias].push(this.$d.convertToDropdownOption(class_, item));
      });
      this.$forceUpdate();
    },
    get(use_cache = false) {
      this.clearRetrievedData();
      this.getFromCacheOrNew(this.relationship, this._index_params, use_cache);
    },
    getFromCacheOrNew(relationship, _index_params = {}, use_cache = true) {

      let from_class_name = relationship['to']['class_name'];
      let relationship_name = relationship['relationship_name'];
      let to_class_name = relationship['to']['class_name'];

      let to_class = this.$d.getClass(to_class_name);
      let to_class_name_title_property_keys = this.$d.getTitlePropertyKeys(to_class)
      var index_params = {}; 

      index_params['with_virtual_properties'] = [];
      index_params['properties_sort'] = [];

      // console.log('title_property_keys', to_class, to_class_name_title_property_keys)

      to_class_name_title_property_keys.forEach((title_property_key, index,) => {
        let title_property =  this.$d.getProperty(to_class['properties'], title_property_key);
        let is_virtual = false;
        if(title_property){
          is_virtual = _.get(title_property, ['virtual_property'], false) == true
        }
        if(is_virtual){
          index_params['with_virtual_properties'].push(title_property_key)
        }
        index_params['properties_sort'].push(
          {
            property_key: title_property_key,
            direction: "asc"
          })
      })

      index_params = Object.assign(index_params, _index_params);
      index_params = Object.assign(index_params, this.$d.getRelationToSearch(relationship, this.params, true, 'to'));

      let index_params_hash = this.$d.objHash(index_params);

      let stateName = 'dropdown_' + from_class_name + '_' + relationship_name + '_' + index_params_hash;

      this.$d.getCache(stateName, (class_data, cache_age) => {
        if (use_cache && class_data && cache_age <= this.max_cache_age) {
          console.log('dropdown using cache:', stateName, cache_age);
          this.processRetrievedData(class_data, relationship);
          this.$emit('reload'); // Note: Have to update the 'relationship_options' first before refresh all dropdowns.
        } else {
          if (!use_cache) {
            console.log('dropdown skip cache');
          } else {
            console.log('dropdown no cache or too old', stateName);
          }
          this.$d.request(
            'index',
            to_class_name,
            index_params,
            class_data => {
              this.processRetrievedData(class_data, relationship);
              this.$emit('reload'); // Note: Have to update the 'relationship_options' first before refresh all dropdowns.
              this.$d.setCache(stateName, class_data);
            },
            error_message => {
              this.error_message = error_message;
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown  {
  .dropdown_select {
    &:disabled {
      background-color: #f7f7f7 !important;
      opacity: 1 !important;
      cursor: not-allowed !important;
      color: #000;
    }
  }
}
</style>
